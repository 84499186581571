.workshops {
  padding: 100px 0;
  background: #fff;
  color: $black;
  @include md {
    padding: 80px 0; }
  &__title {
    margin-bottom: 34px;
    font-size: clamp(36px, 6vw, 72px);
    @include black;
    line-height: 1.2;
    text-align: center; }
  &__description {
    max-width: 1070px;
    margin: 0 auto 100px;
    font-size: 18px;
    text-align: center;
    @include md {
      margin-bottom: 60px; }
    @include sm {
      font-size: 16px; }
    p {
      &:not(:last-child) {
        margin-bottom: 24px;
        @include md {
          margin-bottom: 15px; } } } }
  &__content:not(:last-child) {
    margin-bottom: 90px;
    @include md {
      margin-bottom: 40px; } }
  &__calendar-btn {
    height: 40px; }
  .container {
    max-width: 1280px; }

  &__btn {
    display: flex;
    justify-content: center;
    .btn {
      min-width: 140px; } } }
.workshop {
  color: $black;
  position: relative;
  padding: 35px 40px;
  margin-bottom: 40px;
  border: 4px solid #EAEAEA;

  &:last-child {
    margin-bottom: 0; }
  @include sm {
    padding: 20px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 15px;
    border-width: 3px; }

  .workshop-item {
    padding: 20px 0;

    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0; }
    @include sm {
      padding: 15px 0; }
    &:not(:last-child) {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: -40px;
        right: -40px;
        height: 1px;
        bottom: 0;
        pointer-events: none;
        background: #EAEAEA;
        @include sm {
          left: -20px;
          right: -20px; } } } } }

.workshop-item {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @include md {
    flex-direction: column; }
  &_lg {
    align-items: flex-start;
    .workshop-item__title {
      font-size: 26px;
      letter-spacing: 0.65px;
      @include bold;
      @include sm {
        font-size: 22px; } }
    .workshop-item__content {
      padding-top: 1px;
      ul {
        list-style-type: disc;
        margin-left: 20px; }
      @include md {
        padding-top: 0; } } }
  &_end {
    justify-content: flex-end; }

  &__title {
    flex: 1 0;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.35px;
    @include md {
      margin-bottom: 10px; } }

  &__avatars {
    display: flex;
    width: 80%;
    height: auto;
    margin: 15px 0 0;
    max-width: 250px;
    flex-wrap: wrap;
    align-items: flex-start;
    @include md {
      margin-bottom: 10px; }

    .workshop-item__avatar {
      width: 49%;
      margin: 0 0 2%;

      &:nth-child(2n) {
        margin-left: 2%; } } }

  &__avatar {
    width: 80%;
    height: auto;
    margin: 15px 0 0;
    max-width: 250px;
    display: block;

    @include md {
      margin-bottom: 10px; } }

  &__content {
    max-width: 70%;
    margin-left: 20px;
    flex: 1 1 100%;
    @include md {
      margin-left: 0;
      max-width: 100%; }
    h3 {
      font-size: 22px;
      letter-spacing: 0.55px;
      &:not(:last-child) {
        margin-bottom: 17px; }
      @include sm {
        font-size: 20px; } }
    p, ul, ol {
      color: #666;
      font-size: 15px;
      line-height: 2;
      letter-spacing: 0.3px;

      &:not(:first-child) {
        margin-top: 16px; }

      @include sm {
        color: #666;
        font-size: 14px;
        line-height: 1.7; } }

    ol, ul {

      margin-left: 16px;
      li {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0; } } }

    ol {
      list-style-type: decimal; }
    ul {
      list-style-type: disc; }

    p::empty {
      display: none; }

    a {
      color: $black;
      text-decoration: underline; } } }
