.socials {
	display: flex;
	margin: auto 0;

	&_xl {
		.socials__link {
			width: 28px;
			height: 28px; }
		.socials__item {
			&:not(:last-child) {
				margin-right: 30px; } }
		.icon {
			width: 100%;
			height: 100%; } }

	&_xxl {
		.socials__link {
			width: 49px;
			height: 37px; }
		.socials__item {
			&:not(:last-child) {
				margin-right: 40px; } }
		.icon {
			width: 100%;
			height: 100%; } }

	@include xs {
		justify-content: center; }

	&__item {
		line-height: 0;
		font-size: 17px;
		&:not(:last-child) {
			margin-right: 20px;
			@include tablet {
				margin-right: 18px; } } }
	&__link {
		display: block;
		width: 22px;
		text-align: center;

		.icon {
			fill: $white;
			transition: fill .3s ease;

			&.icon-sl {
				transform: scale(1.2); } }
		@include hover {
			.icon {
				fill: $themeColor2; } } } }


.socials-btns {
	display: flex;
	@include md {
		padding-left: 5px; }
	&__item {
		line-height: 0;
		background-color: $themeColor;
		width: 131px;
		height: 131px;
		border: 4px solid $white;
		position: relative;
		transition: transform .3s ease;
		@include hover {
			transform: translate(-8px, 7px);
			&:before {
				transform: translate(8px, -7px); } }
		@include tablet {
			width: 79px;
			height: 79px; }
		@include md {
			width: 74px;
			height: 74px; }
		&:not(:last-child) {
			margin-right: 80px; }

		&:before {
			position: absolute;
			content: '';
			display: block;
			width: calc(100% + 7px);
			height: calc(100% + 7px);
			border-left: 4px solid $white;
			border-bottom: 4px solid $white;
			top: 4px;
			left: -12px;
			pointer-events: none;
			transition: transform .3s ease; } }
	&__link {
		font-size: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		@include tablet {
			font-size: 27px; }
		@include md {
			font-size: 24px; }
		.icon {
			fill: $themeColor2; }
		&:not(:last-child) {
			margin-right: 20px; } }
	&_transparent {
		.socials-btns {
			&__item {
				background-color: transparent;
				border-color: $themeColor;
				.icon {
					fill: red; }
				&:before {
					border-color: $themeColor; } } } }
	&_sm {
		padding-left: 10px;
		@include sm {
			padding-right: 10px;
			justify-content: space-between; }
		.socials-btns {
			&__item {
				width: 74px;
				height: 74px;
				@include tablet {
					width: 66px;
					height: 66px; }

				@include md {
					width: auto;
					height: auto;
					border: none;
					&:before {
						display: none; } }
				&:not(:last-child) {
					margin-right: 28px;
					@include tablet {
						margin-right: 22px; } } }
			&__link {
				font-size: 29px;
				@include tablet {
					font-size: 22px; }
				@include md {
					font-size: 24px; } } } }
	&_yellow {
		.socials-btns__item {
			border-color: $themeColor2;
			&:before {
				border-color: $themeColor2; } } } }

