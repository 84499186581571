.text-content {
  padding: 163px 0 36px;
  color: #ff2407;
  background: #eaeaec;
  @include md {
    padding: 83px 0 55px; }
  &__inner {
    max-width: 879px;
    .text {
      &:not(:last-child) {
        margin-bottom: 147px;
        @include tablet {
          margin-bottom: 100px; }
        @include md {
          margin-bottom: 80px; }
        @include sm {
          margin-bottom: 32px; } } } } }

.text {
  &__title {
    @include medium;
    font-size: 57px;
    @include sm {
      font-size: 32px; }
    &:not(:last-child) {
      margin-bottom: 57px;
      @include tablet {
        margin-bottom: 40px; }
      @include md {
        margin-bottom: 32px; } } }
  &__descr {
    a {
      color: inherit;
      @include hover {
        text-decoration: none; } } }

  &__subtitle {
    margin-bottom: 65px;
    @include medium;
    font-size: 44px;
    @include sm {
      font-size: 28px;
      margin-bottom: 25px; } }

  &__img {
    margin-bottom: 60px;

    img {
      max-width: 100%;
      height: auto; } } }
