.sec-perks, .sec-perks.articles {
	background: #fff;
	color: $black;
	padding: 100px 0;
	@include md {
		padding: 80px 0; } }

.perks {
	position: relative;
	padding: 35px 40px;
	margin-bottom: 40px;
	max-width: 1274px;
	margin-left: auto;
	margin-right: auto;
	border: 4px solid #EAEAEA;
	@include sm {
		padding: 20px;
		margin-left: -10px;
		margin-right: -10px;
		margin-bottom: 15px;
		border-width: 3px; }
	&:last-child {
		margin-bottom: 0; }
	&__inner {
		display: flex;
		justify-content: space-between;
		max-width: 1274px;
		margin: 0 auto;
		@include md {
			flex-direction: column; } }
	&__img {
		width: 44.59%;
		margin-right: 40px;
		flex-shrink: 0;
		@include md {
			margin-right: 0;
			width: 100%;
			margin-bottom: 25px; }
		img {
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 400px;
			display: block;
			margin: 0 auto;
			@include md {
				max-width: 60%;
				max-height: 300px; }
			@include sm {
				max-width: 80%;
				max-height: 200px; }
			@include xs {
				max-width: 100%;
				max-height: 200px; } } }

	&__video-wrap {
		width: 47%;
		margin-right: 60px;
		flex-shrink: 0;
		margin: 5px 60px 0 0;

		iframe {
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			position: absolute; }

		@include md {
			margin-right: 0;
			width: 100%;
			margin-bottom: 20px;
			padding-top: 0; } }

	&__video {
		position: relative;
		padding-top: 56%;
		width: 100%; }


	&__content,
	&__title {
		font-size: 22px;
		flex: 1 1 auto;
		color: $black;
		margin-bottom: 15px;
		@include bold;

		@include md {
			margin-bottom: 15px;
			font-size: 22px; } }
	&__subtitle {
		font-size: 20px;
		color: $black;
		margin-bottom: 35px;
		@include bold;

		@include md {
			margin-bottom: 15px;
			font-size: 18px; } }
	&__description {
		color: $black;
		font-size: 18px;
		line-height: 1.75;
		@include medium;
		font-weight: normal;
		margin-bottom: 43px;
		letter-spacing: 0.03em;
		@include md {
			margin-bottom: 20px;
			font-size: 14px; }

		ul {
			list-style: disc; }

		a {
			color: $black;
			text-decoration: underline;
			&:hover {
				text-decoration: none; } }

		p {
			&:not(:last-child) {
				margin-bottom: 23px;
				@include md {
					margin-bottom: 10px; } } } }
	&__link {
		text-decoration: none;
		color: $black;
		text-decoration: underline;
		font-size: 14px;
		text-transform: uppercase;
		&:hover {
			text-decoration: none; } } }
