.program-cards {
	margin-bottom: 90px;

	@include md {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap; }

	@include sm {
		flex-direction: column;
		align-items: center; } }

.program-card {
	padding-left: 23px;
	padding-right: 20px;
	margin-left: -23px;
	margin-top: 60px;
	@include medium;
	font-size: 20px;
	line-height: 1.6;
	color: #ff2407;
	position: relative;

	@include md {
		width: 320px; }

	@include sm {
		max-width: 400px;
		width: 100%;
		padding-right: 0;
		padding-left: 0;
		margin-left: 0;
		margin-right: 0;
		margin-top: 20px; }


	a {
		color: #ff2407;

		&:hover {
			text-decoration: none; } }


	&__body {
		z-index: 1;
		position: relative;
		background: #fff;
		display: flex;
		min-height: 225px;

		@include md {
			height: 100%;
			flex-direction: column; } }



	&__left {
		flex: 1 0 32%;
		padding: 30px 35px 30px 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include tablet {
			padding: 20px; }

		@include md {
			flex: 1 0; } }


	&__mid {
		flex: 1 0 36%;
		display: flex;

		@include md {
			flex: 1 0; } }


	&__right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1 0 32%;
		padding: 5px 56px;

		@include tablet {
			padding: 5px 28px; }

		@include md {
			flex: 1 0;
			padding: 10px 28px; } }


	&__img {
		max-width: 100%;
		height: auto;
		display: block;
		object-fit: cover; }

	&:after,
	&:before {
		position: absolute;
		content: '';
		display: block;
		border: 4px solid #fff;
		width: calc(100% - 42px);
		height: calc(100% + 2px);
		background-color: #eaeaec;

		@include sm {
			width: 100%; } }



	&:before {
		top: 18px;
		left: 2px;

		@include sm {
			display: none; } }

	&:after {
		top: 8px;
		left: 12px;

		@include sm {
			left: -9px; } }

	&__title {
		@include medium;
		font-size: 20px; }

	&__date {
		@include medium;
		font-size: 26px; }

	&__social {
		@include regular;
		font-size: 18px;
		margin-top: 22px;

		.icon-sl {
			width: 21px;
			height: 21px;
			fill: #ff2407;
			position: relative;
			top: 4px; } }

	&__register {
		@include medium;
		font-size: 20px;
		margin-top: 22px; }

	&__location {
		@include regular;
		font-size: 18px;
		line-height: 1.8;

		a {
			display: inline-block;
			margin: 7px 0; } } }

