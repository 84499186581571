
@mixin GPFont {
  font-family: GothamPro, Arial, sans-serif; }

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  overflow-y: auto;
  align-items: flex-start;
  padding: 20px;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(15px);
  display: none;
  --accentColor: #0F0;

  @include sm {
    padding: 10px; }
  &.is-active {
    display: flex; } }

.p-promo {
  margin: auto;
  background: #161616;
  border-radius: 20px;
  position: relative;
  width: 100%;
  max-width: 820px;
  color: #fff;
  font-family: var(--brandFont, 'GothamPro'), Arial, sans-serif;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 400;
  --padding: 40px 40px;
  @include sm {
    --padding: 36px 20px;
    max-width: 400px; }
  &__close {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 50%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    @include sm {

      top: 6px;
      right: 6px; }
    .icon {
      stroke: #000;
      color: #000;
      font-size: 12px;
      fill: currentColor;
      position: relative; } }

  &__content {
    border-radius: 20px 20px 0 0;
    background-size: cover;
    padding: var(--padding);
    position: relative;
    z-index: 1;

    background: linear-gradient(-30deg, #0B0B0B 13.39%, rgba(24, 24, 24, 0.00) 99.4%);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: url('../img/popup-promo-bg.webp') no-repeat center;
      z-index: -1;
      pointer-events: none; } }
  &__footer {
    padding: var(--padding);
    background: #161616;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px 60px;
    border-radius: 0 0 20px 20px;
    @include sm {
      gap: 20px; } }
  &__subscribe {
    flex: 1;
    min-width: 1px; }
  &__sponsorship {
    width: 244px;
    flex: none;
    @include sm {
      width: 100%; } } }

.p-promo__input {
  border: 1px solid #383838;
  background: #383838;
  padding: 0 20px;
  height: 39px;
  border-radius: 100px;
  display: block;
  width: 100%;
  color: #fff;
  @include GPFont;
  font-size: 14px;
  font-weight: 400;
  @include sm {
    padding: 0 15px; }
  &:focus {
    border-color: #666; }
  &::placeholder {
    color: #E1E1E1; } }

.p-promo__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  text-align: center;
  gap: 10px;
  @include GPFont;
  line-height: 1.2;
  font-size: 12px;
  font-weight: 400;
  height: 39px;
  border: 2px solid #383838;
  border-radius: 100px;
  background: transparent;
  padding: 0 25px;
  color: #fff;
  text-decoration: none;
  transition: border-color 0.2s linear, background 0.2s linear, color 0.2s linear;
  &:hover {

    text-decoration: none;
    border-color: var(--accentColor); }
  &--lg {
    height: 56px;
    font-size: 14px;
    padding: 0 45px;
    text-transform: uppercase;
    font-weight: 500;
    @include sm {
      height: 48px; } }
  &--block {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px; }
  &--accent {
    background: var(--accentColor);
    color: #111;
    border-color: var(--accentColor);
    &:hover {
      background: #161616;
      color: #fff; } } }

.promo-event {
  display: flex;
  flex-direction: column;
  gap: 30px 0;
  @include sm {
    gap: 20px 0; }
  &__text {
    font-size: clamp(20px, 3.2vw, 28px);
    width: 100%;
    font-weight: 700;
    @include GPFont;
    @include sm {
      font-size: 20px;
      text-align: center; } }

  &__top {
    display: flex;
    align-items: center;
    gap: 15px 40px;
    @include sm {
      flex-wrap: wrap;
      gap: 15px; } }

  &__img {
    width: 80px;
    height: 80px;
    flex: none;
    background: #252525;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

    @include sm {
      width: 50px;
      height: 50px; } }
  &__name {
    font-size: 26px;
    font-weight: 700;
    flex: 1;
    @include sm {
      font-size: 18px;
      flex: auto;
      width: calc(100% - 65px); } }

  &__info {
    flex: none;
    width: 220px;
    display: flex;
    align-items: center;
    gap: 15px;
    @include sm {
      margin-left: auto;
      margin-right: auto;
      width: max-content;
      max-width: 100%; }
    .icon {
      flex: none;
      font-size: 33px;
      color: #9A9A9A;
      @include sm {
        font-size: 28px; } }
    &-text {
      font-size: 16px;

      @include sm {
        font-size: 14px; }
      div:not(:last-child) {
        margin-bottom: 3px; } } }

  &__head {
    display: flex;
    align-items: center;
    gap: 15px 20px;
    margin-bottom: 12px;
    @include sm {
      display: none; } }
  &__title {
    font-size: 46px;
    font-weight: 900;
    line-height: .9;

    text-transform: uppercase; }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px 25px;
    .p-promo__btn {
      @include sm {
        width: 100%; } } }

  &__partnership {
    font-size: 14px;
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none; } }
    @include sm {
      font-size: 12px;
      width: 100%;
      text-align: center; } } }

.promo-subscribe, .promo-sponsorship {
  @include GPFont;
  &__head {
    display: flex;
    align-items: center;
    gap: 16px;
    &-icon {
      flex: none;
      background: #252525;
      width: 52px;
      height: 52px;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 22px;
        color: #9A9A9A; } }
    &-text {
      flex: 1;
      font-size: 14px;
      color: #E1E1E1;
      @include sm {
        font-size: 12px; } } }

  &__form {
    margin-top: 18px;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    @include sm {
      margin-top: 12px; }

    &-field {
      flex: 1;
      min-width: 1px; }
    .p-promo__btn {
      @include sm {
        padding-left: 15px;
        padding-right: 15px; } } } }
