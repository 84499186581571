.s-discount-form {
  padding: clamp(50px, 5vw, 100px) 0;
  background: #1B1B1B;

  .container {

    @include xxs {
      padding: 0 10px; } } }



.discount-form {

  &__title {
    text-align: center;
    @include black;
    font-size: clamp(32px, 5vw, 52px);
    margin: 0 auto clamp(32px, 5vw, 52px);
    color: $grayD;
    line-height: 1.04; }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 768px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 10px 20px;

    @include sm {
      display: flex;
      flex-direction: column;
      padding: 0;
      max-width: 380px; } }

  &__field {
    display: flex;

    &.textarea {
      grid-area: 1 / 2 / 3 / 3;

      @include sm {
        min-height: 150px; } } }

  &__input,
  &__textarea {
    @include medium;
    width: 100%;
    min-height: 70px;
    line-height: 1.24;
    background: $white;
    border-radius: 35px;
    padding: 14px 24px;
    min-width: 100%;
    max-width: 100%;
    display: block;
    font-size: 16px;
    background: $gray;
    border: none;
    color: $white;

    @include sm {
      min-height: 60px;
      font-size: 14px; } }


  &__textarea {
    resize: none;
    padding: 26px 24px;
    min-height: 100%; }

  &__submit {
    @extend .btn--border;
    min-height: 70px;
    font-size: 16px;
    display: flex;

    @include sm {
      position: relative;
      right: auto;
      bottom: auto;
      width: 100%;
      min-height: 60px;
      font-size: 14px; } } }


