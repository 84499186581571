.dates-map {
	position: relative;
	width: 100%;
	margin: -224px auto 60px;
	z-index: 1;

	@include tablet;

	@include md {
		margin-top: -110px; }

	.map {
		height: 514px;
		width: 100%; }

	.pin {
		font-size: 20px;
		color: #fff;
		font-weight: 400;

		@include sm {
			font-size: 16px; } }

	&__inner {
		border: 4px solid $white;
		height: 100%;
		position: relative;
		//&:before, &:after
		//osition: absolute
		//ontent: ''
		//isplay: block
		//idth: calc(100% + 8px)
		//eight: calc(100% + 9px)
		//order: 4px solid $white
		//include md
		//display: none
		&:before {
			top: 5px;
			left: 7px; }
		&:after {
			top: 15px;
			left: 17px; } }
	&__decor {
		position: absolute;
		width: calc(100% + 8px);
		height: calc(100% + 9px);
		border-left: 4px solid $white;
		border-bottom: 4px solid $white;
		top: 6px;
		right: 6px;
		&:before, &:after {
			position: absolute;
			content: '';
			display: block;
			background-color: $white; }
		&:before {
			height: 4px;
			width: 8px;
			top: 0;
			left: 0; }
		&:after {
			height: 8px;
			width: 4px;
			bottom: 0;
			right: 0; }

		@include md {
			display: none; }
		&_2 {
			top: 16px;
			right: 16px; }
		// &_3
		// 	top: 54px
		// 	left: 57px
		// 	&:before
		// 		width: 38px
		// 	&:after
		// 		height: 38px
		// &_4
		// 	top: 65px
 }		// 	left: 67px
	&__content {
		height: 100%;
		position: relative;
		z-index: 2; }

	&__pins {
		display: none; } }
