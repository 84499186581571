.coc {
	padding: 74px 0 80px;
	color: $black;
	&__description {
		text-align: center;
		font-size: 20px;
		line-height: 1.6;
		max-width: 970px;
		margin: 40px auto 0;
		@include md {
			font-size: 18px; }
		@include xs {
			font-size: 16px; }
		a {
			color: inherit;
			text-decoration: none;
			&:hover {
				text-decoration: underline; } }
		p {
			&:not(:last-child) {
				margin-bottom: 40px; } } }
	&__content {
		h2,h3 {
			font-size: 48px;
			line-height: 1.2;
			margin: 80px 0 40px;
			text-align: center;
			@include md {
				font-size: 38px;
				margin: 60px 0 30px; }
			@include sm {
				font-size: 32px;
				margin: 40px 0 20px; } }
		ul,ol {
			font-size: 16px;
			line-height: 1.64;
			margin: 24px 0;
			padding-left: 20px;
			list-style: none;

			li {
				position: relative;
				padding-left: 20px; } }

		ul {
			li {
				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 9px;
					width: 8px;
					height: 8px;
					background: $themeColor;
					border-radius: 50%; } } }

		ol {
			counter-reset: section;
			padding: 0;

			li {
				&:before {
					color: $themeColor;
					display: inline-block;
					font-weight: 700;
					min-width: 20px;
					counter-increment: section;
					content: counters(section,".") " "; } } }

		p {
			font-size: 16px;
			line-height: 1.64;
			@include light;

			& + p {
				margin-top: 16px; } }

		a {
			color: $themeColor;
			text-decoration: underline;

			&:hover {
				text-decoration: none; } } } }

