.s-speakers {
  padding: clamp(50px, 5vw, 100px) 0;
  .container {
    max-width: 1340px; }

  .section {
    &__title {
      max-width: 1060px;
      margin-left: auto;
      margin-right: auto;
      color: $white;
      text-align: center; } } }

.s-mc,
.s-committee {
  background: #353535;
  .speaker {
    color: #e1e1e1; } }

.s-past-speakers {
  .section__title {
    font-size: clamp(28px,4vw,40px); } }

.speakers-list, .mc-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 40px 20px;

  @include xxs {
    gap: 20px 10px;
    grid-template-columns: repeat(auto-fill, minmax(158px, 1fr)); }

  &.short {
    justify-content: center; }

  &__item {
    min-width: 1px;
    &_cfp, &_ask {
      color: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border: 1px solid $gray;
      border-radius: 20px;
      text-align: center;
      @include xs {
        padding: 20px 10px; }

      a.btn {
        min-width: 100%;
        min-height: 62px;
        font-size: 16px;
        border-color: $themeColor2;
        @include xs {
          font-size: 12px;
          padding-left: 10px;
          padding-right: 10px;
          min-height: 42px; } }

      .speaker {

        &__name {
          font-size: 24px;
          @include xs {
            font-size: 22px; } }
        &__company {
          margin: 8px 0 24px; } } }

    &_ask {
      .speaker {
        &__head {
          display: none; } } }

    &_more {
      display: flex;
      flex-direction: column;
      gap: 10px;
      a.btn {
        min-width: 100%;
        min-height: 62px;
        margin-top: 0;
        font-size: 16px;
        border-color: $themeColor2;
        @include xs {
          font-size: 12px;
          padding-left: 10px;
          padding-right: 10px;
          min-height: 42px; } } } }
  .btn {
    @extend .btn--border;
    font-size: 14px;
    min-width: 170px;
    min-height: 50px;
    margin: auto 0 0; }

  a {
    color: $themeColor; } }

.cfp {
  height: 100%;
  display: flex;
  flex-direction: column;
  .btn {
    margin-top: auto; } }

.speaker {
  color: #8D8D8D;
  display: grid;

  a {
    color: $themeColor2; }

  &__tag {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    top: 10px;
    background: $black;
    height: 26px;
    padding: 0 10px;
    border-radius: 10px;
    font-size: 12px;
    color: #fff !important; }

  &__photo {
    position: relative;
    padding-bottom: 100%;
    grid-row: 1;
    grid-column: 1;
    img, video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px 20px 0 0;
      transition: all ease 0.1s; }
    video {
      z-index: 1; }
    .activity-holder {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-weight: 900;
      font-size: 16px;
      cursor: pointer;

      .slider {
        height: auto;
        transition: opacity ease 0.1s;
        opacity: 0; }

      &:hover, &:focus-visible {

        & ~ picture img {
          filter: brightness(0.5); }

        .slider {
          opacity: 1; } } } }

  &__head {

    &+p {
      margin: 40px 0 18px; } }

  &__name {
    font-size: clamp(22px, 2vw, 26px);
    @include black;
    line-height: 0.92;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 18px;
    &.ttn {
      text-transform: none; } }

  &__company {
    line-height: 1.6;
    font-size: 14px;
    margin: 8px 0 0;
    display: flex;
    gap: 4px 8px;
    align-items: center; }

  &__talk {
    line-height: 1.6;
    font-size: 14px;
    margin: 12px 0 0;
    color: $white;

    span {
      font-weight: 700; }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } }

  &__desc {
    line-height: 1.6;
    font-size: 12px;
    margin: 12px 0 0;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    & > * {
      display: inline;
      margin: 0; }

    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } }

  &__socials {

    z-index: 2;
    display: flex;
    gap: 1px;
    padding: 10px;
    grid-row: 1;
    grid-column: 1;
    align-self: end; }
  &__social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #353535;

    .icon {
      width: 20px;
      height: 20px;
      fill: #d6d6d6;
      transition: all ease 0.1s;
      &-tw, &-in {
        transform: scale(0.9); } }

    &:hover, &:focus-visible {
      .icon {
        fill: $themeColor2; } } }

  &__popup-btn {
    @include black;

    &:hover {
      text-decoration: none; } } }
