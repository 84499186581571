.tickets-form {
  padding: 30px 40px;
  border-radius: var(--ticketsFormRadius, 8px);
  margin: 24px 0;
  background-color: var(--ticketsFormBg, #292929);
  border: var(--ticketsFormBorder, none);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px 40px;
  position: relative;
  z-index: 1;
  @include md {
    flex-direction: column; }

  @include xs {
    padding: 24px; }

  &__head {
    max-width: 360px;
    width: 100%;
    text-align: left;
    color: var(--ticketsFormColor, #fff );
    @include md {
      text-align: center;
      max-width: none; } }
  &__title {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 10px;
    text-transform: uppercase;
    line-height: 1.2;
    @include sm {
      font-size: 20px; }
    @include xs {
      font-size: 18px; } }

  &__text {
    font-size: 16px;
    @include sm {
      font-size: 14px; } }

  &__group {
    max-width: 590px;
    width: 100%;
    flex: 1;
    display: grid;
    gap: 15px 0;
    grid-template-columns: 55% 45%;
    @include xs {
      grid-template-columns: 100%; }
    &-input {
      input {
        width: 100%;
        height: 100%;
        min-height: 48px;
        padding: 0 45px 0 20px;
        font-family: var(--ff);
        background: var(--ticketsFormInputBg, none);
        border-radius: var(--ticketsFormInputRadius, 0);
        border: var(--ticketsFormInputBorder, 1px solid #7D7D7D);
        text-align: center;
        font-size: 14px;
        color: var(--ticketsFormInputColor, #fff);
        @include xs {
          padding: 0 20px; }
        &::placeholder {
          color: var(--ticketsFormInputColor, #fff); } } }

    .btn {
      margin-left: -40px;
      text-align: center;
      justify-content: center;
      padding: 0 20px;
      text-transform: uppercase;
      font-size: 16px;
      height: 48px;
      min-height: auto;
      @include xs {
        margin-left: 0; } } } }
