.section {

  &__title {
    font-size: clamp(42px, 6vw, 82px);
    font-weight: 800;
    @include black;
    margin-bottom: clamp(44px, 5vw, 64px);
    line-height: 1.04;
    color: $white;

    @include sm {
      text-align: center; }

    &_big {
      font-size: 48px;
      font-weight: 700;
      text-transform: uppercase; } }

  &__subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;
    margin: 30px auto 40px;

    a {
      color: $themeColor2;

      &:hover {
        text-decoration: none; } }

    @include tablet {
      font-size: 16px;
      margin: 30px auto 30px; } }

  &__text {
    max-width: 950px;
    margin: 0 auto;
    @include tablet {
      max-width: 870px; } } }
