.s-separator-text {
  // border-bottom: 2px solid $gray
  padding: 20px 0 clamp(50px, 9vw, 150px);
  background: $gray; }

.separator-text {

  &__title {
    text-align: center;
    font-weight: 500;
    line-height: 1;
    font-size: clamp(44px, 6vw, 86px);

    strong {
      color: $themeColor2;
      font-weight: 500; } } }
