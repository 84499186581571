.s-for-boss {
  padding: clamp(50px, 5vw, 100px) 0;
  background: url(../img/for-boss.jpg) center center no-repeat;
  background-size: cover;

  .section__title {
    text-align: center;
    color: $white;
    font-weight: 800;
    margin-bottom: clamp(24px, 4vw, 38px); } }


.for-boss {

  &__content {}

  &__text {
    display: flex;
    flex-direction: column; }

  &__desc {
    max-width: 800px;
    margin: 0 auto;
    opacity: 0.5;
    font-size: clamp(16px, 2vw, 18px);
    line-height: 1.5;
    text-align: center;

    p {
      &:not(:first-child) {
        margin: 26px 0 0; } } }

  &__img {
    display: none; }

  &__btn {
    @extend .btn--border;
    display: inline-flex;
    min-width: 240px;
    margin: clamp(24px, 5vw, 64px) auto 0; } }
