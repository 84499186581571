
.no-video-modal {
    width: 100%;
    max-width: 500px;

    text-align: center;

    padding: 40px 16px;
    margin: 0 auto;

    &__title {
        font-size: 20px;
        font-weight: 500;
        color: $white; }

    &__button {
        margin: 24px auto 0;
        margin-top: 24px;
        max-width: 210px; }

    .btn {
        height: 50px; } }
