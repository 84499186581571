.event {
  background: url(../img/event-bg.jpg) center top no-repeat;
  background-size: cover;
  padding: clamp(50px, 6vw, 100px) 0;
  position: relative;
  z-index: 1;

  .container {
    max-width: 1180px;
    margin: 0 auto; }

  &__content {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    gap: 30px 70px;

    @include sm {
      gap: 32px;
      grid-template-columns: 1fr; } }

  &__left {
    max-width: 668px;
    grid-column: 1/1; }

  &__venue {
    width: 148px;
    height: 155px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    margin-right: 40px;
    @include sm {
      order: -1;
      margin-right: 0; }

    &-text {
      position: absolute;
      inset: 0;
      animation: rotate-text 8s linear infinite; }

    img {
      max-width: 100%; }
    &:before {
      content: '';
      width: 210%;
      aspect-ratio: 1;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #FEF502;
      filter: blur(200px);
      z-index: -1; } }

  &__heading {
    @include light;
    font-size: 16px;
    margin: 0 0 20px; }

  &__title {
    position: relative;
    @include bold;
    font-size: clamp(34px, 5vw, 82px);
    line-height: 1;
    margin: 0 0 46px;

    &:after {
      content: '';
      position: absolute;
      left: -80px;
      top: 0;
      bottom: 0;
      width: 14px;
      background: #FEF502;

      @include tablet {
        left: -20px;
        width: 8px; }

      @include xxs {
        left: -14px;
        width: 4px; } } }

  &__descr {
    font-size: 16px;
    line-height: 1.5;

    p {

      margin: 0 0 24px;
      &:last-child {
        margin-bottom: 0; }
      &:first-child {
        font-size: 22px;
        @include sm {
          font-size: 18px; } } } }

  &__techs {
    margin-top: 100px;
    text-align: center;
    @include sm {
      margin-top: 70px; } }
  &__techs-title {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 40px;
    @include md {
      margin-bottom: 32px;
      font-size: 18px;
      color: $themeColor2; }

    @include sm {
      margin-bottom: 24px; } }

  &__tip {
    font-size: 16px;
    line-height: 1.6;
    margin-top: 50px;

    @include xs {
      text-align: center;
      font-size: 18px; }
    p {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      align-items: center;
      @include xs {
        flex-direction: column; } }

    a {
      @extend .btn;
      @extend .btn--border;
      display: inline-flex;
      min-height: 50px;
      min-width: 170px;
      font-size: 14px;
      border-color: $themeColor2;
      @include xs {
        width: 100%; }
      &:hover {
        text-decoration: none; } } } }

@keyframes rotate-text {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.event-review {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 70px;
  @include sm {
    margin-top: 50px; }
  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 30px; }
  &__text {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    line-height: 1.5;

    @include md {
      font-size: 20px; }
    @include sm {
      font-size: 16px; }
    a {
      color: var(--accentColor);
      &:hover {
        text-decoration: underline; } } }
  &__author {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    line-height: 1.2;
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;
    @include sm {
      margin-top: 20px;
      gap: 10px; }
    &-img {
      width: 56px;
      height: 56px;
      @include sm {
        width: 48px;
        height: 48px; }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &-content {
      max-width: 150px;
      text-align: left; }
    &-name {
      font-size: 16px;
      font-weight: 600; }

    &-company {
      font-size: 12px;
      color: #A0A0A0;
      margin-top: 5px; } } }
