.img-text {
  display: flex;
  color: #ff2407;
  &_mt {
    margin-top: -100px; }
  &__img {
    background-size: auto 100%;
    background-position: 100% 0;
    width: 100%;
    max-width: 762px;
    flex-shrink: 0; }
  &__content {
    padding: 253px 0 10px 74px; }
  &__inner {
    max-width: 638px; }
  &__title {
    margin-bottom: 69px; }
  &__descr {} }
