.s-teams-cta {
  position: relative;
  padding: clamp(30px, 5vw, 50px) 0 clamp(30px, 5vw, 50px);
  background: $black; }

.teams-cta {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px 0;

  @include sm {
    padding: 40px 0; }

  @include xs {
    padding: 30px 0; }

  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    background: $themeColor2;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 20px; }

  &__desc {
    font-size: clamp(22px, 3vw, 36px);
    text-align: center;
    font-weight: 900;
    line-height: 1.33; }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 50px 0 0;

    @include sm {
      margin: 20px 0 0;
      gap: 10px; } } }
