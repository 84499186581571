
@mixin GPFont {
  font-family: GothamPro, sans-serif; }

.out {
  overflow: clip; }
._visible {
  display: none; }

.sv-title {
  text-align: center;
  font-size: clamp(30px,7vw,48px);
  margin-bottom: 22px;
  line-height: 1;
  font-weight: 900;
  letter-spacing: .01em; }
.shedule-v2 {
  @include GPFont;
  padding: clamp(40px, 5vw, 100px) 0;
  position: relative;
  color: var(--textColor);
  background: var(--bgColor);
  overflow: clip;
  .container {
    max-width: 1560px;
    padding: 0 20px;
    @include tablet {
      padding: 0 20px; } }
  .remote-switch {
    margin: 0 auto 40px; }

  .schedule__tip {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    padding: 0;

    font-size: 14px;

    @include tablet {
      font-size: 12px; }
    @include sm {
      padding: 0 10px; } }

  .js-tabs-container {
    position: relative;
    @include sm {
      margin-left: -20px;
      margin-right: -20px; } }

  .js-tabs-container {
    position: relative;

    @include sm {
      margin-left: -20px;
      margin-right: -20px; } } }

.sv-switch-description {
  text-align: center;
  color: $scheduleColorGray;
  font-size: 16px;
  margin: 20px 0 60px;
  @include tablet {
    font-size: 12px;
    margin-bottom: 30px; } }

.sv-wrapper {
  position: relative;
  &:after, &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    background: var(--bgColor);
    z-index: 3; }
  &:after {
    right: 100%; }
  &:before {
    left: 100%;
    z-index: 1; } }

.sv-tabs {
  display: flex;
  position: sticky;
  top: $posTop;
  z-index: 4;
  height: 60px;
  background: var(--bgColor);
  @include md {
    top: -1px;
    height: 50px; }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0px;
    display: block;
    border-bottom: 1px solid rgba($scheduleOutline, 0.3); }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 110vw;
    left: 50%;
    transform: translate(-50%, 0);
    background: var(--bgColor);
    z-index: 3; }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0 28px;
    font-family: inherit;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    z-index: 4;
    background: transparent;
    border: none;
    @include sm {
      padding: 0 5px;
      flex: 1; }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 2px; }
    &.is-active {
      &:after {
        background: var(--accentColor); }
      .sv-tabs__date {
        color: var(--textColor);
        font-weight: 600; } } }

  &__date {
    font-size: 16px;
    color: $scheduleColorGray;
    font-weight: 700;
    @include sm {
      font-size: 14px; } }
  &__filter {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 10px;
    @include sm {
      padding-left: 15px;
      padding-right: 10px; } }

  &__btn {
    align-self: center;
    height: 36px;
    min-width: 55px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 6px;
    overflow: hidden;
    color: rgba(#fff, 0.7);
    border: none;
    position: relative;
    z-index: 4;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    font-family: inherit;
    font-size: 12px;
    font-weight: 700;
    @include tablet {
      min-width: 40px; }
    @include md {
      min-width: 30px;
      padding: 0;
      height: 30px; }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: var(--accentColor);
      opacity: 0;
      transition: opacity 0.2s linear; }

    span {
      margin-left: 7px;
      @include md {
        display: none; } }
    .icon {
      width: 18px;
      height: 18px;
      fill: rgba(#fff, 0.7);
      display: block;
      @include md {
        width: 16px;
        height: 16px; } }
    &:hover {
      &:before {
        opacity: 0.2; } }
    &.is-active {
      color: #000;
      .icon {
        fill: #000; }
      &:before {
        opacity: 1; } } } }

.sv-body {
  width: 100%;
  position: relative;
  padding-top: 15px;
  &:not(.is-active) {
    display: none; }

  &__swiper {
    overflow: visible !important;
    padding-left: 55px !important;

    @include tablet {
      padding-left: 45px !important; }
    @include sm {
      padding-right: 10px !important; }
    .swiper-wrapper {
      z-index: 2; }

    .swiper-slide {
      width: 340px !important;
      @include sm {
        width: 240px !important; }

      &:first-child {
        .sv-time__zone {
          display: block; } } }
    .swiper-scrollbar {
      position: sticky !important;
      bottom: 10px !important;
      background: rgba(0,0,0,.2) !important;
      height: 10px !important;
      .swiper-scrollbar-drag {
        background: rgba(0,0,0,.7) !important; } }

    &--loading {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 110vw;
        transform: translate(-50%, 0);
        bottom: 0;
        background: var(--bgColor);
        z-index: 6; }
      &:before {
        content: "";
        display: block;
        position: sticky;
        top: 50px;
        left: calc(50% - 37px);

        z-index: 7;
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #e9e9e9;
        border-color: #e9e9e9 transparent #e9e9e9 transparent;
        animation: loading 1.2s linear infinite; } } } }

.sv-nav {
  position: sticky;
  z-index: 4;
  top: $posTop + 59px;
  padding-top: 8px;
  padding-bottom: 4px;
  margin-bottom: -5px;
  background: var(--bgColor);

  @include md {
    gap: 5px;
    top: 48px; }

  &__item {
    min-width: 1px;
    flex: 1;
    padding: 2px 10px;
    height: 34px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--accentColor);
    font-weight: 700;
    position: relative;
    box-sizing: border-box;

    @include sm {
      padding: 2px 6px; }
    &:before {
      content: '';
      background: var(--accentColor);
      opacity: 0.2;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      pointer-events: none; }
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      right: 100%;
      width: 16px;
      height: calc(100% + 8px + 4px);
      background: var(--bgColor); } } }

.sv-time-zone {
  position: absolute;
  top: 0px;
  left: 0;
  padding-top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 44px;
  background: var(--bgColor);
  z-index: 4;
  box-sizing: border-box;

  @include tablet {
    width: 41px; }

  &__item {
    position: relative;
    padding: 20px 0;
    box-sizing: border-box;
    &:first-child {
      padding-top: 5px;
      &:after {
        display: none; } }
    &[style*="height: 0px"], &[style*="height: 60px"], &[style*="height: 70px"], &[style*="height: 40px"] {
      display: none; }
    &:before {
      content: '';
      position: absolute;
      top: 20px;
      bottom: -40px;
      left: 20px;
      border-left: 1px solid rgba($scheduleOutline, 0.3); }

    &:last-child:before {
      bottom: -15px; } }
  &__badge {
    position: sticky;
    top: $posTop + 75px;
    left: 0;
    z-index: 1;
    width: 44px;
    line-height: 20px;
    white-space: nowrap;
    font-size: 11px;
    text-align: center;
    background: var(--accentColor);
    color: #000;
    padding: 0;
    font-weight: 600;
    border-radius: 5px;
    box-sizing: border-box;
    @include tablet {
      width: 37px;
      font-size: 10px; }

    @include md {
      top: 64px; }
    @include sm {
      left: 2px; } } }

.sv-time {
  display: flex;
  flex-direction: column;
  background: transparent;
  padding: 20px 0;
  position: relative;
  box-sizing: border-box;

  &--null {
    &[style*="height: 0px"], &[style*="height: 60px"], &[style*="height: 70px"], &[style*="height: 40px"] {
      display: none; } }

  &:not(:nth-child(2)) {
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: -8px;
      right: -8px;
      height: 1px;
      background: rgba($scheduleOutline, 0.3); } } }

.sv-item {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 8px;
  height: auto;
  overflow: hidden;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  border: var(--schedule-item-border, none);
  @include sm {
    padding: 12px; }

  &:not(:last-child) {
    margin-bottom: 10px; }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--accentColor);
    opacity: 0.07;
    z-index: -1;
    pointer-events: none; }
  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
    background: transparent;
    border: none;
    text-align: left;
    padding: 0;
    border-radius: inherit; }
  &__top-wrap {
    flex: none; }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: none;
    margin-bottom: 8px; }
  &__time {
    flex: none;
    font-size: 12px;
    line-height: 1.4;
    color: var(--textColor); }
  &__title {
    flex: none;
    font-weight: 700;
    line-height: 1.3;
    font-size: 18px;
    @include sm {
      font-size: 14px; }

    &--pointer {
      cursor: pointer; } }

  &__subtitle {
    flex: none;
    font-size: 12px;

    position: relative;
    padding-left: 10px;
    @include sm {
      font-size: 10px; }
    &:not(:last-child) {
      margin-bottom: 4px; }
    &-symbol {
      position: absolute;
      left: 0;
      font-weight: 700;
      font-size: 14px;
      top: -1px;
      @include sm {
        top: -2px; } }
    &-name {
      color: var(--textColor); } }
  &__speakers {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 15px;
    margin-top: 10px; }

  &__speaker {
    color: var(--textColor);
    flex: none;
    width: calc(50% - 8px);
    gap: 10px;
    display: flex;
    align-items: center;
    &:first-child:last-child {
      width: 100%; }

    &-content {
      flex: 1;
      min-width: 1px; }
    &-avatar {
      flex: none;
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    &-name {
      white-space: nowrap;
      font-size: 12px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include sm {
        font-size: 10px; } }
    &-location {
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
      color: $scheduleColorGray;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include sm {
        font-size: 10px; } } }

  &__company {
    color: $scheduleColorGray;
    flex: none;
    font-size: 12px;
    margin-top: 18px;
    @include sm {
      font-size: 10px; } }

  &__button {
    height: 20px;
    padding: 0;
    flex: none;
    align-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    border: none;
    color: var(--textColor);
    background: transparent;
    white-space: nowrap;
    @include GPFont;
    font-size: 12px;
    text-decoration: none;
    position: relative;
    z-index: 2;
    @include sm {
      font-size: 10px; }
    span {
      margin-right: 2px; }
    &:hover {
      color: var(--accentColor); } }

  &__body {
    line-height: 16px;
    position: relative;
    flex: none;
    color: $scheduleColorGray;
    font-size: 12px;
    @include sm {
      font-size: 10px;
      line-height: 14px; } }
  &__activities {
    margin-top: 10px; }
  &__text {
    margin-top: 10px;
    @include ellipsis(3);
    & > * {
      display: none;
      &:first-child {
        display: block;
        @include ellipsis(3); } }

    a {
      color: inherit;
      text-decoration: underline;
      position: relative;
      z-index: 3;
      &:hover {
        text-decoration: none; } } }

  &--null {
    display: none; }

  &--hover {
    // flex: 400 1 auto
    @media (any-hover: hover) {
      &:hover {
        .sv-item__title {
          text-decoration: underline; } } } } }

@keyframes loading {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }

.short-v {
  ._hidden {
    display: none !important; }
  ._visible {
    display: inline-block; }
  .sv-item {
    gap: 4px;
    &__top {
      margin-bottom: 0; }
    &__speakers {
      gap: 0 5px;
      margin-top: 2px; }
    &__speaker {
      width: auto; }
    &__button {
      display: none; }

    &__title {
      text-indent: 42px;
      margin-top: -18px;
      font-size: 14px;
      line-height: 1.4; } } }
