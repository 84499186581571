
.btn {
  display: inline-flex;
  text-align: center;
  background-color: $themeColor2;
  border: 1px solid $themeColor2;
  border-radius: 35px;
  padding: 4px 30px;
  min-height: 70px;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  @include medium;
  color: $black;
  text-decoration: none;
  transition: all ease 0.3s;
  transform: translateZ(0) perspective(1px);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-backface-visibility: hidden;

  @include md {
    min-height: 56px;
    padding: 4px 16px;
    font-size: 16px; }

  &:hover, &:focus-visible {
    background: $white;
    border-color: $white; }

  &:focus-visible {
    outline-width: 3px !important; }

  &--border {
    background: transparent;
    border-color: $themeColor2;
    color: $white;

    &:hover, &:focus-visible {
      background: $white;
      color: $black;
      border-color: $white; } }

  &--huge {

    max-width: 1180px;
    margin: 0 auto;
    @include black;
    line-height: 0.9;
    width: 100%;
    padding: 0 20px;
    min-height: clamp(70px, 18vw, 180px) !important;
    border-radius: 200px;
    background: $themeColor2;
    border: none;
    font-size: clamp(40px, 10vw, 105px) !important;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 2vw;

    &:after,
    &:before {
      display: none; }

    .icon {
      transition: all ease 0.1s;
      fill: $black;
      width: clamp(30px, 9vw, 100px);
      height: clamp(30px, 9vw, 100px); } } }

.is-hidden {
  display: none; }
