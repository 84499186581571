.video-widget {
	position: relative;
	margin: -100px 0 0;
	padding-top: 40px;
	padding-bottom: 20px;
	z-index: 1;

	@include laptop {
		padding-top: 80px; }
	@include tablet {
		padding-top: 72px; }
	@include md {
		padding-top: 50px;
		margin: -60px 0 0; }
	@include sm {
		padding-top: 40px; } }
