.s-teams {
	position: relative;
	padding: 56px 0;
	&__decor-diver {
		position: absolute;
		top: 113px;
		left: 50%;
		margin: 0 0 0 270px;
		width: 356px;
		height: 872px;
		background: url(../img/decor_diver-2.png) center center;
		background-size: cover;
		z-index: 0;

		@include md {
			top: 50px;
			width: 284px;
			height: 694px;
			left: auto;
			right: 30px;
			margin: 0;
			opacity: 0.6; }

		@include xs {
			top: 100px;
			width: 180px;
			height: 442px;
			left: auto;
			right: 30px;
			margin: 0; } }
	&__button {
		margin-top: 40px; }

	&#teams {
		padding-top: 106px;
		@include md {
			padding-top: 56px; }

		@include sm {
			padding-top: 28px; } }


	.section {

		&__title {
			font-size: clamp(28px, 5vw, 58px);
			text-align: left;
			color: #fff;
 }			// font-weight: 600

		&__head-l {
			max-width: 850px; } }


	@include md {
		.container {
			padding-left: 20px;
			padding-right: 20px; } }

	@include sm {
		padding: 28px 0;
		.container {
			padding-left: 10px;
			padding-right: 10px; } } }
