.techs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
  gap: 20px 5px;

  &__item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 90px;
    max-width: 90px;
    @include xs {
      flex: 1 1 76px;
      max-width: 76px; } }

  &__link {
    text-decoration: none;
    color: #fff;
    transition: color .1s;

    &:hover {
      text-decoration: underline;
      color: $themeColor2; } }

  &__img-box {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 9px;
    @include xs {
      margin: 0 0 0; }

    img {
      transform: scale(0.80);
      max-height: 58px;

      @include md {
        transform: scale(0.75); } } }

  &__title {
    font-size: 11px;
    text-align: center; } }
