
.sec-banner {
  padding-bottom: 40px;
  .container {
    @include sm {
      padding-left: 15px;
      padding-right: 15px; } } }
.s-banner {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 30px 70px;
  padding: 70px clamp(40px, 6vw, 110px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--banner-radius, 0);
  @include md {
    padding-top: 50px;
    padding-bottom: 50px; }
  @include sm {
    flex-direction: column;
    padding: 32px; }
  @include xxs {
    padding: 30px 16px; }

  &__logos {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
    flex: 0.9;
    @include md {
      flex: 0.7; }
    @include sm {
      flex: auto;
      flex-direction: row;
      align-items: center;

      gap: 50px; }
    @include xs {
      gap: 30px; }
    @include xxs {
      gap: 18px; }
    &-item {
      @include sm {
        flex: 1 1 auto;
        max-width: 140px; } }
    img {
      max-width: 308px;
      width: 100%;
      @include md {
        max-width: 180px; }
      @include sm {
        width: auto;
        max-width: 100%; } } }

  &__content {
    flex: 1.1;
    @include md {
      flex: 1.3; }
    @include sm {
      flex: auto; } }
  &__title {
    grid-row: 1/2;
    grid-column: 2/3;
    font-size: 48px;
    font-weight: 700;
    color: $themeColor;
    text-transform: uppercase;
    margin-bottom: 30px;
    font-family: var(--banner-font);
    line-height: 1;
    @include md {
      font-size: 38px;
      margin-bottom: 20px; }
    @include sm {
      font-size: 32px;
      font-weight: 400; }
    @include xxs {
      font-size: 28px; }

    &-2 {
      display: none;
      @include sm {
        display: block; } }
    &-1 {
      @include sm {
        display: none; } } }

  &__text {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 1.2;
    @include xs {
      font-size: 12px; }
    p {
      margin-bottom: 20px; }

    a {
      text-decoration: underline;
      color: $themeColor;
      &:hover {
        text-decoration: none; } } }

  &__price {
    margin-top: 50px;
    font-size: 94px;
    font-weight: 700;
    line-height: 1;
    font-family: var(--banner-accent-font);
    @include md {
      margin-top: 30px;
      font-size: 74px; }
    @include xs {
      font-size: 64px; }
    @include xxs {
      font-size: 58px; } }
  .btn {
    margin-top: 30px;
    min-width: 188px;
    height: 52px;
    font-weight: 700;
    text-transform: uppercase;
    min-height: auto;
    font-size: var(--banner-btn-size);
    @include md {
      margin-top: 20px; }
    @include sm {
      width: 100%; } } }
