.remote-switch-wrap {
  text-align: center; }
.remote-switch {
  font-size: 12px;
  display: inline-flex;
  height: 50px;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  background: var(--accentColor);
  border-radius: 5px;
  pointer-events: auto;

  @include laptop {
    left: 50px;
    transform: translateX(0); }

  @include md {
    height: 38px;
    left: 0; }

  &__text {
    opacity: 0.8;
    text-decoration: none;
    color: $switchColor;
    font-weight: 400;
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;

    @include md {
      font-size: 10px; }

    &:first-child {
      padding: 0 10px 0 20px;

      @include md {
        padding: 0 10px 0 10px; } }

    &:last-child {
      padding: 0 20px 0 10px;

      @include md {
        padding: 0 10px 0 10px; } }

    &:not(.active):hover {
      color: $switchColor;
      opacity: 1; }

    &.active {
      opacity: 1; } }

  &__switch {
    position: relative;
    width: 36px;
    height: 12px;
    border-radius: 6px;
    background: rgba(0,0,0, 0.2);
    cursor: pointer;

    @include md {
      width: 22px;
      height: 10px; }

    &:after {
      content: '';
      position: absolute;
      left: -3px;
      top: -3px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $switchHandler;

      @include md {
        width: 14px;
        height: 14px;
        top: -2px;
        left: -2px; } }

    &.active {
      &:after {
        left: auto;
        right: -3px;

        @include md {
          left: auto;
          right: -2px; } } } } }
