
//html
////removed becaus it delays navigation on page load
///scroll-behavior: smooth

body {
  @include regular;
  font-size: 16px;
  line-height: 1.375;
  color: $white;
  background: $mainBg;
  @include tablet {
    font-size: 14px; }
  &.is-no-scroll {
    overflow: hidden; } }

.out {
  position: relative;
  overflow: hidden; }

.bg-red {
  background-color: $themeColor; }

.d-flex {
  display: flex; }

.title {
  @include medium;
  font-size: 60px;
  letter-spacing: 0.4px;
  line-height: 1.07;
  @include tablet {
    font-size: 60px; }
  @include md {
    font-size: 44px;
    line-height: 1.1;
    letter-spacing: 0.22px; } }

.container {
  --container: 50px;
  max-width: 1380px;
  margin: 0 auto;
  padding: 0 var(--container);
  @include tablet {
    --container: 20px;
    max-width: 850px; }

  @include xxs {
    --container: 14px; } }

.color-yellow {
  color: $themeColor2; }

.descr {
  font-size: 18px;
  line-height: 2;
  @include tablet {
    font-size: 16px; }
  @include md {
    font-size: 14px; } }

.link {
  color: inherit;
  display: inline-flex;
  align-items: center;
  .icon {
    fill: $white;
    margin-right: 9px;
    font-size: 26px;
    vertical-align: sub;
    @include tablet {
      font-size: 24px; }
    @include md {
      font-size: 19px;
      margin-right: 4px; } } }
.title-img {
  padding: 40px 0;
  img {
    max-width: 100%;
    margin: 0 auto;
    display: block; } }

.anchor {
  position: relative;
  top: -80px; }

.anchor-item {
  text-decoration: none;
  color: unset;
  &:hover {
    text-decoration: underline; } }

#popup-ticket {
  a[href="/quick-access"] {
    border-color: $themeColor2;
    background-color: $themeColor2;
    color: $black;

    &:hover {
      border-color: $white;
      background-color: $white; }

    &:before,
    &:after {
      display: none; } } }

.pop-ticket__btn {
  &.btn {
    &.btn--border {
      border-color: $themeColor2;
      background-color: $themeColor2;
      color: $black;

      &:hover {
        border-color: $white;
        background-color: $white; }

      &:before,
      &:after {
        display: none; } } } }

.seo-text {
  p, ul, ol {
    margin-bottom: 15px; }
  ol, ul {
    margin-left: 20px; }
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal; }
  li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0; } }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none; } } }

.sec-banner {
  background: #353535;
  padding-bottom: 60px !important;
  .container {
    max-width: 1260px; } }

.s-banner__title {
  font-weight: 700 !important; }

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
  display: inline-block;
  stroke: currentColor;
  stroke-width: 0; }
