.s-awards {
	color: $themeColor;
	background: $white;
	padding: clamp(50px, 8vw, 100px) 0 30px;

	.container {
		max-width: 1140px; }

	.section {
		&__title {
			max-width: 655px;
			color: $black;

			&:not(:last-child) {
				margin-bottom: 52px;
				@include tablet {
					margin-bottom: 37px; } } } } }


.awards {
	display: flex;
	justify-content: space-between;
	color: $gray;
	@include md {
		flex-direction: column-reverse; }

	&__content {
		max-width: 600px;
		margin-right: 100px;
		//flex-shrink: 0
		@include tablet {
			margin-right: 50px; }
		@include md {
			max-width: 100%;
			margin-right: 0; } }

	&__descr {
		line-height: 1.5;

		p {
			&:not(:first-child) {
				margin: 16px 0 0; } }

		a {
			font-weight: 500px; } }


	&__illustration {
		margin-top: -162px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		max-width: 575px;
		@include tablet {
			margin-top: 5px;
			align-items: flex-start; }
		@include md {
			margin: 0 auto 43px; }
		img {
			width: 100%;
			max-width: 100%; }
		.title {
			line-height: 0.9; } }

	&__img {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: $themeColor2;
		width: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		margin: -30px 0 0;

		@include md {
			margin: 0 0 30px; } }

	&__text {
		font-size: clamp(20px, 4vw, 30px);
		@include black;
		letter-spacing: .4px;
		text-transform: uppercase; } }

.osa-img {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 155px;
		bottom: 0;
		transform: translateY(40%);
		width: 191px;
		height: 98px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='191' height='98' fill='none'%3E%3Cg fill='%23353535' fill-opacity='.3' style='mix-blend-mode:overlay'%3E%3Cpath d='M0 98V0h17.304v98H0ZM34.609 98V0h5.768v98h-5.768ZM46.145 98V0H57.68v98H46.145ZM69.217 98V0h5.769v98h-5.769ZM80.754 98V0h5.768v98h-5.768ZM92.29 98V0h5.768v98H92.29ZM103.826 98V0h17.304v98h-17.304ZM138.435 98V0h5.768v98h-5.768ZM149.971 98V0h11.536v98h-11.536ZM173.043 98V0h5.769v98h-5.769ZM184.58 98V0h5.768v98h-5.768Z'/%3E%3C/g%3E%3C/svg%3E");
		z-index: 2;
		mix-blend-mode: overlay;
		pointer-events: none;

		@include sm {
			left: 80px;
			width: 173px;
			height: 76px;
			background-size: contain; } }

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 450px;
		height: 450px;
		border-radius: 50%;
		background: rgba(33, 33, 33, 0.2);
		z-index: 0;
		filter: blur(267.495px);
		pointer-events: none; }

	&__container {
		position: relative;
		display: flex;
		flex-direction: column;
		text-align: center;
		width: 366px;
		height: 366px;
		border-radius: 50%;
		background: linear-gradient(131.33deg, rgba(255, 255, 255, 0.4) 18.45%, rgba(255, 255, 255, 0.1) 73.67%);
		box-shadow: 0px 3.44751px 21.547px -2.58564px rgba(0, 0, 0, 0.06), 0 -1px rgba(255, 255, 255, 1.0), 0 1px rgba(255, 255, 255, 0.5);
		backdrop-filter: blur(20px);
		z-index: 1;
		justify-content: center;
		align-items: center;
		margin: 0;

		@include md {
			width: 320px;
			height: 320px; }

		@include sm {
			width: 232px;
			height: 232px; }

		.icon {
			width: 74px;
			height: 94px;
			fill: $black;
			mix-blend-mode: overlay;
			opacity: 0.4;

			@include sm {
				width: 52px;
				height: 66px; } } }

	&__title {
		display: flex;
		flex-direction: column;
		text-transform: uppercase;
		font-size: 17px;
		line-height: 1.06;
		margin: 28px 0 0;
		color: $black;
		mix-blend-mode: overlay;
		opacity: 0.4;
		@include bold;

		span {
			@include black;
			font-size: 48px; }

		@include sm {
			margin: 18px 0 0;
			font-size: 12px;

			span {
				font-size: 28px; } } }

	&__circle-1 {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 98%;
		height: 98%;
		border-radius: 50%;
		background: conic-gradient(from 121.41deg at 50% 50%, #FFFFFF -58.88deg, #141313 78.22deg, #000000 116.08deg, #FFFFFF 239.62deg, #FFFFFF 258deg, #FFFFFF 301.12deg, #141313 438.22deg);
		box-shadow: 0px 45.5311px 65.94px -19.3507px rgba(0, 0, 0, 0.36);
		filter: blur(20px);
		opacity: 0.60;

		@include md {
			width: 310px;
			height: 310px; }

		@include sm {
			width: 230px;
			height: 230px; } }

	&__circle-2 {
		position: absolute;
		left: -9px;
		bottom: 78px;
		width: 68px;
		height: 68px;
		border-radius: 50%;
		background: linear-gradient(95.52deg, #BDFF00 14%, #00C635 87.81%);
		display: none;

		@include sm {
			left: -8px;
			bottom: 65px;
			width: 48px;
			height: 48px; } }

	&__circle-3 {
		position: absolute;
		right: 35px;
		bottom: 5px;
		width: 26px;
		height: 26px;
		border-radius: 50%;
		background: linear-gradient(95.52deg, #BDFF00 14%, #00C635 87.81%);
		display: none;

		@include sm {
			right: 30px;
			bottom: 0px;
			width: 18px;
			height: 18px; } } }
