.h-list {
  font-size: clamp(14px, 2.2vw, 20px);
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-weight: 600;

  &__item {
    display: flex;
    gap: 0 5px;

    @include xs {
      gap: 0; } }

  &__value {
    display: inline-flex;
    color: $themeColor2; }

  &__text {
    line-height: 1.2;

    br {
      display: none; } }

  &__text-top {
    color: $themeColor2; } }

.info-container {
  padding: 42px 0 24px;
  @include md {
    padding: 24px 0; }
  &__inner {
    display: flex;
    justify-content: center;
    @include xs {
      flex-direction: column;
      align-items: center; }
    .info {
      &:not(:last-child) {
        margin-right: 106px;
        @include sm {
          margin-right: 50px; }
        @include xs {
          margin: 0 0 20px; } } } } }
