.submit {
	color: $themeColor;
	&__title {
		@include bold;
		font-size: 30px;
		line-height: 1.2;
		text-transform: uppercase;
		@include xs {
			padding: 0 26px 0 65px;
			font-size: 26px; }
		&:not(:last-child) {
			margin-bottom: 16px;
			@include md {
				margin-bottom: 8px; } } }
	&__descr {
		@include medium;
		font-size: 22px;
		line-height: 1.2;
		@include tablet {
			font-size: 20px; }
		@include md {
			font-size: 16px;
			line-height: 1.5; }
		@include xs {
			padding: 0 26px 0 65px; }
		&:not(:last-child) {
			margin-bottom: 46px;
			@include tablet {
				margin-bottom: 37px; }
			@include md {
				margin-bottom: 34px; } } }
	&__btn {
		display: flex;
		align-items: flex-start;
		.btn {
			color: $themeColor;
			@include tablet {
				min-width: 274px; }
			@include md {
				padding-top: 11px;
				padding-bottom: 11px; }
			@include xs {
				width: 100%; } } } }
