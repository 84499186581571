.zoom-bars {
	padding: clamp(50px, 7vw, 100px) 0;
	color: $black;

	.container {
		max-width: 1260px; }

	.section {
		&__title {
			color: $white;
			text-align: center;
			font-size: clamp(32px, 4vw, 52px);
			margin-bottom: clamp(20px, 3vw, 42px);
			text-align: center; }

		&__subtitle {
			color: $white;
			text-align: center; } }

	+ .zoom-bars {
		padding-top: 0; } }


.bars-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 50px 0 0;
	color: $white;
	gap: 10px;

	@include sm;

	@include xs {
		gap: 0 10px;
		margin: 30px -15px 0; }

	&__item {
		position: relative;
		display: flex;
		font-size: 20px;
		overflow: hidden;
		border-radius: 20px;
		flex: 1 1;
		min-width: 300px;
		max-width: 380px;
		flex-direction: column;
		align-items: flex-end;
		flex-wrap: wrap;
		padding: 20px 40px;
		min-height: 314px;

		@include md {
			padding: 20px 30px; }

		@include sm {
			padding: 20px 18px; }

		&.no-photo {

			.bars-list__name {}

			&:hover {
				.bars-list__bg {
					transform: scale(1.1); } } }

		&.no-hover {

			&:hover {
				.bars-list__bg {
					transform: scale(1); } } }


		@include xs {
			width: 100%; }

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5); } }

	&__link {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		cursor: pointer;
		&:focus-visible {
			&:after {
				content: '';
				position: absolute;
				inset: 0;
				border-radius: 20px;
				border: 3px solid rgba(#fff, 0.5); }
			& + .bars-list__bg {
				transform: scale(1.1); } } }


	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all ease 0.2s; }

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		z-index: 1;
		height: 100%;
		width: 100%;
		justify-content: flex-end; }

	&__bar {
		display: flex;
		line-height: 1;
		margin: 0 0 20px;
		font-size: 34px;
		@include black; }

	&__with {
		display: none; }

	&__name {
		font-size: 12px;
		line-height: 1.5;
		min-height: 72px;

		a {
			color: $themeColor2; } }

	&__rooms {
		display: flex;
		margin: auto 0 0; }

	&__room {
		position: relative;
		border: 13px solid #fff;
		border-radius: 50%;
		overflow: hidden;
		flex: 0 0 auto;
		background: #fff;
		cursor: pointer;

		@include tablet {
			border-width: 10px; }

		&+.bars-list__room {
			margin: 0 0 0 -62px;

			@include sm {
				margin: 0 0 0 -5vw; }

			@include xs {
				margin: 0 0 0 -10vw; }

			@include xxs {
				margin: 0 0 0 -15vw; } }

		&:after {
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			content: 'JOIN';
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			opacity: 0;
			transition: all ease 0.1s;
			color: $themeColor2; }

		&:hover {
			border-color: $themeColor2;

			&:after {
				opacity: 1; } } }

	&__photo {
		display: block;
		width: 175px;
		height: 175px;
		max-width: 170px;
		max-height: 170px;

		@include tablet {
			width: 12vw;
			height: 12vw; }

		@include sm {
			width: 20vw;
			height: 20vw; }

		@include xs {
			width: 30vw;
			height: 30vw; }

		@include xxs {
			width: 45vw;
			height: 45vw; } } }
