.media-box {

	&__grid {
		height: 100%;
		width: 100%;
		position: relative; } }

.m-grid {
	align-items: flex-start;
	justify-content: center;
	align-content: flex-start;
	display: flex;
	flex-wrap: wrap;

	&__content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
		transition: opacity .3s ease;
		.icon {
			fill: $white;
			font-size: 68px; } }
	&__item {
		margin: 0 0 -1px;
		height: calc((100vw / 4) - 30px);
		display: block;
		position: relative;
		@include hover {
			.m-grid__content {
				opacity: 1; }
			.m-grid__pop {
				opacity: 0; } }
		@include tablet {
			height: calc((100vw / 4) - 10px); }
		@include sm {
			height: calc(100vw / 1.65); }
		.video {
			position: absolute;
			width: 100%;
			height: 100%; } }
	&__img {
		@include vcenter;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		opacity: 1;
		transition: opacity .3s ease;
		&_hover {
			opacity: 0; } }

	&__pop {
		@include regular;
		color: $white;
		font-size: 1.3vw;
		line-height: 1.5;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		padding: 0 20%;
		background: rgba(0, 0, 0, 0.5);
		transition: opacity .3s ease;
		opacity: 1;

		@include md {
			font-size: 1.6vw; }
		@include sm {
			font-size: 3vw;
			padding: 0 15%; }

		p:last-child {
			@include light;
			font-size: 1.2vw;
			position: absolute;
			right: 20%;
			bottom: 20%;
			font-style: italic;
			@include md {
				font-size: 1.5vw; }
			@include sm {
				font-size: 2.5vw;
				right: 15%; } } }

	img {
		height: 100%;
		width: 100%;
		object-fit: cover; }

	&__1 {
		width: 50%;
		@include sm {
			// display: none
			order: -2;
			width: 100%; } }

	&__2 {
		width: 50%;
		@include sm {
			display: none;
			width: 100%; } }

	&__3 {
		width: 25%;
		@include sm {
			width: 50%; } }

	&__4 {
		width: 50%;
		@include sm {
			width: 100%;
			order: -1; } }

	&__5 {
		width: 25%;
		@include sm {
			width: 50%; } }

	&__6 {
		width: 50%;
		@include sm {
			width: 100%; } }

	&__7 {
		width: 50%;
		@include sm {
			display: none; } } }
