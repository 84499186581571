.s-dates {
	padding: 0;
	margin-top: clamp(32px, 6vw, 86px);

	.container {
		padding: 0;
		max-width: none !important; } }

.dates {
	display: flex;
	margin: 0 auto;
	width: 100%;
	gap: 20px;
	overflow-x: auto;
	padding-bottom: 20px;
	min-width: 0;

	@include tablet {
		// justify-content: unset
 }		// scroll-snap-type: x mandatory

	@include sm {
		gap: 10px; }

	&:after,
	&:before {
		content: '';
		position: relative;
		width: 0; }

	&:before {
		margin: 0 0 0 auto; }

	&:after {
		margin: 0 auto 0 0; }

	&::-webkit-scrollbar {
		width: 2px;
		height: 2px; }

	&::-webkit-scrollbar-button {
		width: 0px;
		height: 0px; }

	&::-webkit-scrollbar-thumb {
		background: #FFF;
		border: 0px solid #fff;
		border-radius: 0px;
		cursor: pointer; }

	&::-webkit-scrollbar-thumb:hover {
		background: #FFF; }

	&::-webkit-scrollbar-thumb:active {
		background: #FFF; }

	&::-webkit-scrollbar-track {
		background: #404040;
		border: 0px solid transparent;
		border-radius: 0px; }

	&::-webkit-scrollbar-track:hover {
		background: #404040; }

	&::-webkit-scrollbar-track:active {
		background: #404040; }

	&::-webkit-scrollbar-corner {
		background: transparent; }

	&__item {
		display: inline-flex;

		@include tablet {
			scroll-snap-align: center; }

		@include sm; }

	&__icon {
		position: absolute;
		font-size: 36px;
		right: -108px;
		top: 50%;
		transform: translate(-50%, -17px);
		display: none;

		.icon {
			fill: #ffffff; } } }

.date {
	background: rgba(255, 255, 255, 0.1);
	position: relative;
	display: flex;
	text-decoration: none;
	border-radius: 20px;
	overflow: hidden;
	color: #e2e2e2;

	&:hover {
		color: #FEF502;

		.date {
			&__name {
				text-decoration: none; }

			&__bg {
				opacity: 1; } } }

	@include md {
		color: #FEF502;

		.date {
			&__name {
				text-decoration: none; }

			&__bg {
				opacity: 1; } } }

	&.js-scroll-to {
		.date {
			&__name {
				text-decoration: underline; } } }

	&__bg {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		transition: all 0.2s ease;
		filter: brightness(0.5);
		opacity: 0; }

	&__header {
		position: relative;

		@include tablet {
			margin-bottom: 4px; } }

	&__title {
		font-size: 14px;
		min-height: 1.5;
		display: flex;
		justify-content: space-between;
		margin-bottom: 68px; }

	&__inner {
		overflow: hidden;
		position: relative;
		z-index: 2;
		padding: 26px 30px 26px;
		width: 310px;
		max-width: 100%;
		background-size: cover;
		transition: all .3s ease; }

	&__date {
		@include black;
		font-size: 72px;
		line-height: 1;
		text-decoration: none;

		@include sm {
			font-size: 70px; } }

	&__name {
		font-size: 30px;
		min-height: 60px;
		@include black;
		text-transform: uppercase;
		line-height: 1;
		display: block;
		position: relative;
		margin-top: 66px; }

	&__info {
		font-size: 14px;
		line-height: 1.6;
		text-align: left;
		position: relative;
		margin-top: 20px;

		a {
			color: $white; } } }





