.about {
	display: flex;
	&__content {
		width: 50%;
		padding: 154px 90px 205px 0; }
	&__inner {
		max-width: 553px;
		margin-left: auto; }
	&__title {
		margin-bottom: 58px; }
	&__descr {}

	&__socials {
		width: 50%;
		background-size: auto 100%;
		background-position: left top;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		padding-left: 85px;
		padding-bottom: 177px; } }
