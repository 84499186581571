
.faq {
  margin: 0 !important;
  background: #353535;
  padding: 50px 0;
  color: #fff;

  .container {
    max-width: 1010px; }

  &__description {
    font-size: 18px;
    margin: 0 0 20px;
    padding: 0 0 50px;

    @include sm {
      font-size: 16px; }

    p {
      margin: 20px 0 0;
      a {
        color: #fef502; } } }

  &__section-title {
    font-size: 22px;
    line-height: 1.36;
    margin: 40px 0 20px;
    text-transform: uppercase;
    color: #a3a3a3;
    @include black; }

  &__arrow {
    display: none; }
  &__item {
    position: relative;
    border-radius: 20px;
    background: #212121;
    z-index: 1;
    margin-top: 4px;
    &:first-child {
      margin-top: 0; }

    &.open, &:hover {
      background: #181818;
      .faq__question {
        color: #fff;
        &:after {
          border-color: #fff; } } }

    &.open {
      .faq__question {
        &:after {
          transform: rotate(-135deg);
          margin-top: -5px; } } } }

  &__question {
    font-size: 20px;
    line-height: 1.36;
    cursor: pointer;
    font-weight: 700;
    padding: 20px 90px 20px 40px;
    color: #AEAEAE;
    position: relative;
    text-align: left;
    display: block;
    width: 100%;
    background: transparent;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 30px;
      margin-top: -8px;
      height: 12px;
      width: 12px;

      border-bottom: 2px solid #AEAEAE;
      border-right: 2px solid #AEAEAE;
      transform: rotate(45deg); } }

  &__ansver {
    font-size: 16px;
    line-height: 1.5;
    display: none;
    padding: 0 40px 25px;
    max-width: 840px;
    color: #AEAEAE;

    a {
      color: #fef502; }

    p {
      margin: 0 0 16px;

      &:last-child {
        margin: 0; } }
    ul, ol {
      line-height: 1.3;
      margin-bottom: 15px; }

    ol {
      list-style-type: decimal;
      padding-left: 20px; }
    ul {
      li {
        position: relative;
        padding-left: 1.2em;
        &:before {
          content: '';
          position: absolute;
          top: 0.5em;
          left: 0;
          height: 0.3em;
          width: 0.3em;
          border-radius: 50%;
          background-color: #AEAEAE; }
        &:has(ul), &:has(ol) {
          &:before {
            display: none; } } } } }

  @include sm {
    &__item {
      border-radius: 15px; }
    &__question {
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      padding: 15px 50px 15px 20px;
      &:after {
        right: 20px; } }

    &__ansver {
      font-size: 14px;
      padding: 0 20px 15px; } }

  @include xxs {

    &__question {
      font-size: 16px; }

    &__section-title {
      font-size: 22px;
      margin: 30px 10px 10px; } } }

@include sm {
  .faq__description {
    padding: 0; } }
