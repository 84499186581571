.cards {
  padding: 100px 0;
  color: $black;
  background: $white;
  @include md {
    padding: 80px 0; }
  &__title {
    margin-bottom: 34px;
    font-size: clamp(36px, 6vw, 72px);
    @include black; }
		line-height: 1.2;

  &__descr {
    margin: 0 0 100px;
    font-size: 18px;
    @include md {
      margin-bottom: 60px; }
    @include sm {
      font-size: 16px; }
    p {
      &:not(:last-child) {
        margin-bottom: 24px;
        @include md {
          margin-bottom: 15px; } } } }
  &__item {
    &:not(:last-child) {
      margin-bottom: 50px;
      @include md {
        margin-bottom: 20px; } } }
  &_lg {
    padding-bottom: 150px;
    .cards {
      &__title {
        max-width: 865px; }
      &__descr {
        max-width: 880px; } } }
  &_sm {
    padding: 13px 0 62px;
    @include tablet {
      padding-top: 0; }
    @include md {
      padding-top: 0; }
    .cards {
      &__title {
        font-size: 57px;
        @include tablet {
          font-size: 44px; }
        @include md {
          font-size: 32px; } } } } }

.card {
  width: 100%;
  position: relative;

  &__inner {
    display: flex;
    border: 4px solid #EAEAEA;
    padding: 30px;
    position: relative;
    z-index: 1;
    @include md {
      display: block; }
    @include sm {
      padding: 15px;
      margin-left: -5px;
      margin-right: -5px;
      border-width: 3px; } }
  &__col {
    width: 100%;

    &_sm {
      width: 44.59%;
      flex-shrink: 0;
      @include md {
        width: 100%; } }
    &_img {
      width: 50%;
      line-height: 0;
      @include md {
        width: 100%; }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include md {
          height: auto; } }
      .card__img {
        width: 100%;
        height: 100%;
        @include md {
          height: auto; }
        &:after {
          display: none; } } } }
  &__content {
    padding: 0 10px 0 40px;
    border-left: none;
    @include md {
      padding: 25px 0 0 0; } }

  &__img {
    width: 100%;
    line-height: 0;
    position: relative;

    img {
      width: 100%; } }
  &__title {
    @include bold;
    font-size: 22px;
    &:not(:last-child) {
      margin-bottom: 15px; } }
  &__descr {
    line-height: 1.75;
    font-weight: normal;

    a {
      color: #ff2407; }

    &:not(:last-child) {
      margin-bottom: 19px;
      @include md {
        margin-bottom: 10px; } } }
  &__link {
    font-size: 18px;
    line-height: 1.9;
    @include medium;
    color: inherit;
    @include hover {
      text-decoration: none; }
    @include sm {
      font-size: 16px; } }
  &__soc-link {
    margin: 27px 0;
    @include sm {
      margin: 10px 0; }
    .link {
      &__icon {
        .icon {
          fill: #ff2407; } } } }
  &_reverse {
    .card {
      &__content {
        padding: 0 40px 0 10px; } } }

  &__list {
    margin: 18px 0;
    list-style: disc inside; } }
