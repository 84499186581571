.schedule-btn-wrap {
  background: #353535;
  padding: clamp(20px, 5vw, 50px) 0;
  .container {
    max-width: 1380px; } }

// .schedule
//   padding: 80px 0 0
//   margin: 0 0 80px
//   color: $black

//   .section
//     &__title
//       color: $white
//     &__subtitle
//       color: $white

//   &+.schedule
//     padding: 0px 0 0

//     .section__title
// 			// display: none

//   +laptop
//     padding: 70px 0 0
//   +sm
//     padding: 40px 0 0
//     margin: 0 0 50px

//   .container
//     max-width: 1280px
//     +xxs
//       padding: 0

//   &__tip
//     color: #888
//     margin: 0 0 10px
//     +xxs
//       padding: 0 20px

//   &__item
//     border-top: 2px solid $grayL
//     &:last-child
//       border-bottom: 2px solid $grayL
//   &__tab-links
//     display: flex
//     margin-bottom: 30px
//     +md
//       margin-bottom: 20px
//   &__tab-link
//     +medium
// 		font-size: 20px
// 		min-height: 44px
// 		display: flex
// 		align-items: center
// 		text-align: center
// 		color: #FFF
// 		text-decoration: none
// 		position: relative
// 		text-transform: uppercase
// 		margin: 0 0 0 56px
// 		cursor: pointer
// 		transition: all ease 0.2s
//     +first
//       margin: 0
//     +md
//       font-size: 20px
//       padding: 10px
//       margin: 0 0 0 36px
//     +sm
//       font-size: 18px
//       margin: 0 0 0 10px
//     +xs
//       font-size: 14px
//       padding: 10px 5px
//     &:after
//       content: ''
//       position: absolute
//       bottom: 0
//       left: 0
//       width: 100%
//       height: 0
//       transition: 0.3s
//       background-image: linear-gradient(-210deg, $themeColor2 0%, $themeColor2 100%)
//     &.is-active
//       &:after
//         height: 4px
//         +sm
//           height: 4px
//     .no-touch &:hover
//       color: $themeColor2
//   &__tabs
//     +xs
//       margin: 0
//   &__tab
//     display: none
//     &.is-active
//       display: block

//   &__btn
//     min-height: 50px
//     border: 2px solid #d7d7d7
//     background: transparent
//     padding: 5px 20px
//     width: 170px
//     font-size: 12px
//     margin: 15px 0 0

//     +tablet
//       padding: 5px 20px

//     &:hover
//       background: $themeColor2
//       border-color: $themeColor2

// .s-item
//   background: #fff
//   border-left: 1px solid $grayL
//   border-right: 1px solid $grayL
//   &__row
//     display: flex
//     +sm
//       flex-wrap: wrap
//     & + &
//       margin-top: 20px
//   &__col
//     padding: 16px 28px
// 		// border-right: 2px solid $grayL
//     +last
//       border: none
//     &_d-sm-none
//       +sm
//         display: none
//     +md
//       padding: 16px 18px
//     &:nth-child(1)
//       text-align: center
//       width: 9.7%
//       +sm
//         width: 80px
//         min-width: 80px
//         border: none
// 				// border-bottom: 1px solid #202421
//     &:nth-child(2)
//       width: 26.3%
//       +sm
// 				//width: auto
//         flex-grow: 1
//         padding-left: 0
// 				// border-bottom: 1px solid #202421
//     &:nth-child(3)
//       width: 64%
//       +sm
//         width: 100%
//         margin-top: 10px
//         margin: 0
//         padding-top: 0
//   &__time
//     font-size: 14px
//     font-weight: 500
//     margin: 4px 0
//     color: $themeColor

//   &__title
//     font-size: 18px
//     color: $themeColor
//     a
//       color: $themeColor
//     +medium
//     +md
//       font-size: 18px
//     +sm
//       font-size: 16px
//       margin: 2px 0
//     & + .s-item__text
//       margin-top: 8px
//     & + .s-item__company
//       margin-top: 8px
//       +sm
//         margin-top: 5px
//     &_sm
//       font-size: 16px
//       margin: 18px 0 0

//       +md
//         font-size: 16px

//       +sm
//         font-size: 14px
//         margin: 12px 0 0

//     &:first-child
//       margin-top: 0

//   &__text
//     letter-spacing: 0.3px
//     color: $black
//     line-height: 1.7
//     +sm
//       color: $black
//     img
//       float: left
//       width: 30%
//       margin-right: 24px
//     a
//       color: $black
//   &__company
//     font-size: 14px
//     color: $black
//     +sm
//       color: $black

.talks-list {
  .s-item {
    &__row {
      display: flex;
      @include sm {
        flex-wrap: wrap; }
      & + & {
        margin-top: 20px; } }
    &__col {
      padding: 16px 28px;
      border-right: 2px solid $grayL;
      @include last {
        border: none; }
      &_d-sm-none {
        @include sm {
          display: none; } }

      &:nth-child(1) {
        text-align: center;
        width: 30%;
        @include sm {
          text-align: left;
          width: 100%;
          border: none;
          padding: 16px; } }
      &:nth-child(2) {
        width: 70%;
        @include sm {
          width: 100%;
          margin-top: 10px;
          margin: 0;
          padding: 16px;
          padding-top: 0; } } } } }

.talks {
  padding: 149px 0 97px;
  border-bottom: 1px solid #2c302d;
  @include laptop {
    padding: 55px 0 60px; }

  a {
    color: #fff; }
  @include sm {
    border-bottom: none;
    padding-bottom: 20px; }
  .container {
    @include sm {
      padding: 0 10px; } }
  .s-item {
    background: transparent;
    border: none;
    max-width: 1200px;
    margin: 0 auto;
    @include first {
      margin: 0; }

    &__col {
      border: none;
      @include sm {
        padding: 0 20px;
        margin: 16px 0 0;
        @include first {
          margin: 0; } } }

    &__text {
      line-height: 1.6;
      margin-top: 10px;

      a {
        color: $themeColor; } }
    &__title {
      @include regular; } }

  .talk__item {
    margin-top: 6px;
    @include first {
      margin-top: 0; }
    @include sm {
      margin-top: 16px;
      @include first {
        margin-top: 0; } }
    @include xs {
      margin: 10px -10px;
      @include first {
        margin-top: 0; } } } }
