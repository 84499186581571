@include font(GothamPro, GothamProLight, 300);
@include font(GothamPro, GothamPro, 400);
@include font(GothamPro, GothamProMedium, 500);
@include font(GothamPro, GothamProBold, 700);
@include font(GothamPro, GothamProBlack, 900);

@mixin light {
    font-family: 'Manrope', sans-serif;
    font-weight: 300; }

@mixin regular {
    font-family: 'Manrope', sans-serif;
    font-weight: 400; }

@mixin medium {
    font-family: 'Manrope', sans-serif;
    font-weight: 500; }

@mixin bold {
    font-family: 'Sora', sans-serif;
    font-weight: 700; }

@mixin black {
    font-family: 'Sora', sans-serif;
    font-weight: 800; }
