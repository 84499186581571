.twitts {
	padding: 165px 0 150px;
	color: $themeColor;
	background: $grayL;
	@include tablet {
		padding: 112px 0 100px; }
	@include md {
		padding: 75px 0 55px; }
	&__title {
		&:not(:last-child) {
			margin-bottom: 78px;
			@include tablet {
				margin-bottom: 39px; }
			@include md {
				margin-bottom: 33px; } } } }


.twitts-list {
	columns: 3;
	column-gap: 50px;
	@include tablet {
		column-gap: 21px; }
	@include md {
		columns: 2; }
	@include xs {
		columns: auto;
		display: flex;
		flex-direction: column;
		align-items: center; }
	&__item {
		display: inline-block;
		margin: 0 0 20px;
		width: 100%;
		@include tablet {
			margin: 0 0 10px; }
		@include xs {
			max-width: 400px;
			margin-bottom: 0; }

		iframe {
			width: 100% !important; } } }
