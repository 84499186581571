.s-video-rooms {
  padding: clamp(50px, 7vw, 100px) 0;
  background: #333333;

  .container {
    max-width: 1140px; }

  .section {
    &__title {
      color: $white; }

    &__subtitle {
      color: $white; } } }

.video-rooms {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: 62px -20px 0;
  padding: 0;

  @include md {
    margin: 30px -25px 20px; }
  @include sm {
    margin: 10px -25px 10px; }

  &__item {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 30px;
    padding: 20px 20px;
    transition: all ease 0.1s;
    color: #fff;

    @include md {
      gap: 15px; }

    @include sm {
      flex-direction: column; }

    &:not(:first-child) {
      border-top: 1px solid #4E4E4E; } }

		// &:not(:first-child)
		// 	&:after
		// 		content: ''
		// 		position: absolute
		// 		left: -1000px
		// 		right: -1000px
		// 		top: 0
		// 		border-top: 1px solid #4E4E4E

  //&.hoverable

  //  &:hover
  //    color: $white
  //    background: #1B1B1B

  //    .video-rooms__btn
  //      border-color: #ff2407

  //      &:hover
  //        background: #ff2407
  //        color: $white

  &__link {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; }

  &__img-wrap {
    display: flex;
    width: 70px;
    height: 70px;
    justify-content: center; }

  &__img {
    display: block;
    margin: auto;
    max-height: 100%; }

  &__num {
    margin: 0;
    font-weight: 500;
    display: none; }

  &__title {
    display: flex;
    margin: 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    align-items: center;

    @include tablet {
      font-size: 24px; }

    @include sm {
      color: $white; } }

  &__users {
    margin: 0 0 0 auto;
    flex: 0 1;
    display: flex;

    @include sm {
      margin: 0;
      width: min-content; } }

  &__user {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    margin: 0 0 0 -10px;

    &:first-child {
      margin: 0; } }

  &__avatar {
    width: 40px;
    height: 40px;
    display: block;
    object-fit: cover;
    margin: 0;
    border-radius: 50%;

    @include tablet {
      width: 32px;
      height: 32px; } }

  &__name {
    font-size: 14px;
    line-height: 1.2;
    max-width: 192px;
    width: 100%;
    display: flex;
    align-items: center; }

  &__btn {
    margin: auto 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    padding: 0 20px;
    color: #ff2407;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    border: 3px solid transparent;
    text-align: center;
    transition: all ease 0.1s;
    cursor: pointer;
    transition: all ease .1s;
    display: none;

    &:hover {
      color: $white; }

    @include tablet {
      font-size: 12px;
      font-weight: 600; } } }
