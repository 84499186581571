.articles {
  padding-top: 142px;
  color: $white;
  @include md {
    padding-top: 40px; }
  &__title {
    font-size: 33px;
    line-height: 1.39;
    text-align: center;
    max-width: 627px;
    margin: 0 auto 85px;
    @include md {
      margin-bottom: 20px; } }
  &__subtitle {
    text-align: center;
    font-size: 22px;
    max-width: 627px;
    margin: 0 auto; }
  &__content {
    padding: 0 40px;
    @include md {
      padding: 0 20px; } } }

.article {
  padding: 100px 0;
  @include md {
    padding: 30px 0; }
  &:not(:last-child) {
    border-bottom: 1px solid #202421; }
  &__inner {
    display: flex;
    max-width: 1274px;
    margin: 0 auto;
    @include md {
      flex-direction: column; } }
  &__img {
    width: 47%;
    margin-right: 60px;
    flex-shrink: 0;
    padding-top: 5px;
    @include md {
      margin-right: 0;
      width: 100%;
      margin-bottom: 20px;
      padding-top: 0; }
    img {
      max-width: 100%;
      display: block;
      @include md {
        margin: 0 auto; } } }
  &__content {
    flex: 1; }
  &__title {
    font-size: 26px;
    color: $white;
    @include bold;
    margin-bottom: 35px;
    @include md {
      margin-bottom: 15px; }
    @include sm {
      font-size: 22px; } }
  &__description {
    color: #fff;
    font-size: 15px;
    line-height: 1.7;
    margin-bottom: 43px;
    letter-spacing: 1.1px;
    @include md {
      margin-bottom: 20px; }
    @include sm {
      font-size: 14px; }

    ul {
      list-style: disc;
      list-style-position: inside;
      margin-bottom: 16px; }

    a {
      color: #fff; }

    p {
      &:not(:last-child) {
        margin-bottom: 16px;
        @include md {
          margin-bottom: 10px; } } } }
  &__link {
    text-decoration: none;
    color: var(--accentColor);
    font-size: 14px;
    text-transform: uppercase;
    &:hover {
      text-decoration: underline; } }

  &__btns {
    display: flex;
    gap: 50px; } }
